import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Development"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article
        className="post-content page-template no-image"
        style={{
          position: "relative",
        }}
      >
        <div className="trapezoid"></div>
        <div
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            padding: "6vw",
            height: "unset",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="page-quote"
            style={{
              maxWidth: "100%",
              flex: 1,
              maxHeight: "25vh",
            }}
          >
            Know your core competencies and
            <br />
            focus on being great at them.
            <br />– Mark Cuban
          </div>
          <div>
            <h3 id="dynamic-styles">Application and Web Development</h3>
            <p style={{ maxWidth: 600 }}>
              The way we code today will not be the way we code tomorrow. The
              problems we solve for our clients are constantly changing as well.
              We work closely with them to create solutions to those problems.
            </p>
          </div>
          <div>
            <h3>
              <strong>
                <strong>Technologies</strong>
              </strong>
            </h3>
            <ul>
              <li>React</li>
              <li>React Native</li>
              <li>Node</li>
              <li>Gatsby</li>
              <li>AWS</li>
              <li>Modern JavaScript and HTML</li>
            </ul>
            <h3>
              <strong>
                <strong>Platforms</strong>
              </strong>
            </h3>
            <ul>
              <li>iOS</li>
              <li>Android</li>
              <li>Web</li>
            </ul>
          </div>
          <div>
            <h3 id="dynamic-styles">Availability</h3>
            <p style={{ maxWidth: 600 }}>
              We are currently busy maintaining several ongoing projects for our
              existing clients. While we are not actively seeking any new
              clients to take on at this time, feel to reach out if you feel we
              would be a good fit for your project:{" "}
              <a href="mailto:hello@parkerandleigh.com">
                hello@parkerandleigh.com
              </a>
              .
            </p>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    developmentHero: file(relativePath: { eq: "development-hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
